.contact {
    background-color: #fafafa !important;
    color: #383737
}
.contact-divider {
    border-top: 3px solid #383737;
}

form {
    font-weight: 500;
}

.submit {
    background-color: #383737;
    border-color: #383737;
}

.submit:hover {
    background-color: rgba(0,0,0,.9);
    border-color: #383737;
}

.alert {
    margin-top: 1rem;
}