.navbar {
    text-transform: uppercase;
    padding: 1.5rem 1rem;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 900;
    background-color: #fafafa;
}

.navbar-light .navbar-brand {
    flex-direction: row;
    color: #4a5365;
}

.navbar-brand {
    font-size: 1.2em;
    font-weight: 900;
    display: inline;
}

.navbar-collapse {
    justify-content: flex-end;
}

.logo {
    height: 24px;
    width: auto;
}

a {
    text-decoration: none;
    color: #4a5365;
}

a.btn-social-icon {
    border: 2px solid #4a5365;
    border-radius: 100%;
    margin-left: 1em;
    font-size: 0.5em;
    height: 32px;
    width: 32px;
    text-align: center;
    color: #4a5365;
}

.btn-social-icon>:first-child {
    line-height: 30px;
}

.social-icons {
    display: flex;
    background-color: #ce6366;
    justify-content: center;
    padding: 1em;
}

.navbar-light .navbar-toggler {
    color: #4a5365;
}

.buttonLink {
    cursor: pointer;
    border: none;
    background: none;
    text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
    font-size: 1.2em;
    font-weight: 900;
    color: #4a5365;
}

.buttonLink:focus {
    outline: 0;
}

@media (max-width: 576px) {
    .navbar {
        font-size: 0.7em;
    }

    .navbar-nav {
        flex-direction: column;
    }

    a.btn-social-icon {
        border: 2px solid white;
        margin: 0 2em 0 2em;
        color: white;
    }
}