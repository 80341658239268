html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-fluid {
  padding: 0;
}

a {
  cursor: pointer;
}

.navbar {
    text-transform: uppercase;
    padding: 1.5rem 1rem;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: 900;
    background-color: #fafafa;
}

.navbar-light .navbar-brand {
    flex-direction: row;
    color: #4a5365;
}

.navbar-brand {
    font-size: 1.2em;
    font-weight: 900;
    display: inline;
}

.navbar-collapse {
    justify-content: flex-end;
}

.logo {
    height: 24px;
    width: auto;
}

a {
    text-decoration: none;
    color: #4a5365;
}

a.btn-social-icon {
    border: 2px solid #4a5365;
    border-radius: 100%;
    margin-left: 1em;
    font-size: 0.5em;
    height: 32px;
    width: 32px;
    text-align: center;
    color: #4a5365;
}

.btn-social-icon>:first-child {
    line-height: 30px;
}

.social-icons {
    display: flex;
    background-color: #ce6366;
    justify-content: center;
    padding: 1em;
}

.navbar-light .navbar-toggler {
    color: #4a5365;
}

.buttonLink {
    cursor: pointer;
    border: none;
    background: none;
    text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
    font-size: 1.2em;
    font-weight: 900;
    color: #4a5365;
}

.buttonLink:focus {
    outline: 0;
}

@media (max-width: 576px) {
    .navbar {
        font-size: 0.7em;
    }

    .navbar-nav {
        flex-direction: column;
    }

    a.btn-social-icon {
        border: 2px solid white;
        margin: 0 2em 0 2em;
        color: white;
    }
}
.content {
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 900;
    flex-direction: column;
}
.jumbotron {
    padding: 2em 2em;
    background-color: #4C5364;
    color: white;
    margin-bottom: 0;
}
img {
    max-width: 240px;
    border-radius: 50%;
    border: 3px solid #b6b9bb;
}
p {
    text-transform: uppercase;
    margin: 1em 0 1em 0;
}
hr {
    max-width: 20%;
    border-top: 3px solid white;
}
.typewriter {
    display: flex;
    justify-content: center;
}

@media (max-width: 576px) {
    img {
        max-width: 150px;
        border-radius: 50%;
        border: 3px solid #b6b9bb;
    }
    hr {
        max-width: 50%;
        border-top: 3px solid white;
    }
}
.emailWrapper {
    overflow-wrap: break-word;
}
.aboutMe {
    background-color: #c26868;
}
.aboutme-content {
    flex-direction: column;
}
.aboutme-divider {
    max-width: 20%;
}
.aboutme-summary-wrapper{
    margin: 1em 0 1em 0;
}
.aboutme-summary {
    font-size: 1em;
    font-weight: 500;
    text-transform: none;
}
.aboutme-summary-desc {
    font-size: 0.9em;
    list-style: none;
}
.sublime {
    font-family: 'Monaco', 'Courier New';
    background-color: #383737;
    font-size: 0.7em;
    font-weight: 300;
}
.rowmartop {
    margin-top: 1em;
}
.rowmarbottom {
    margin-bottom: 1em;
}
.aboutme-email {
    text-decoration: none;
    color: #66bbef;
}
.aboutme-email:hover {
    text-decoration: none;
    color: #66bbef;
}
.contact {
    background-color: #fafafa !important;
    color: #383737
}
.contact-divider {
    border-top: 3px solid #383737;
}

form {
    font-weight: 500;
}

.submit {
    background-color: #383737;
    border-color: #383737;
}

.submit:hover {
    background-color: rgba(0,0,0,.9);
    border-color: #383737;
}

.alert {
    margin-top: 1rem;
}
