.aboutMe {
    background-color: #c26868;
}
.aboutme-content {
    flex-direction: column;
}
.aboutme-divider {
    max-width: 20%;
}
.aboutme-summary-wrapper{
    margin: 1em 0 1em 0;
}
.aboutme-summary {
    font-size: 1em;
    font-weight: 500;
    text-transform: none;
}
.aboutme-summary-desc {
    font-size: 0.9em;
    list-style: none;
}
.sublime {
    font-family: 'Monaco', 'Courier New';
    background-color: #383737;
    font-size: 0.7em;
    font-weight: 300;
}
.rowmartop {
    margin-top: 1em;
}
.rowmarbottom {
    margin-bottom: 1em;
}
.aboutme-email {
    text-decoration: none;
    color: #66bbef;
}
.aboutme-email:hover {
    text-decoration: none;
    color: #66bbef;
}