.content {
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 900;
    flex-direction: column;
}
.jumbotron {
    padding: 2em 2em;
    background-color: #4C5364;
    color: white;
    margin-bottom: 0;
}
img {
    max-width: 240px;
    border-radius: 50%;
    border: 3px solid #b6b9bb;
}
p {
    text-transform: uppercase;
    margin: 1em 0 1em 0;
}
hr {
    max-width: 20%;
    border-top: 3px solid white;
}
.typewriter {
    display: flex;
    justify-content: center;
}

@media (max-width: 576px) {
    img {
        max-width: 150px;
        border-radius: 50%;
        border: 3px solid #b6b9bb;
    }
    hr {
        max-width: 50%;
        border-top: 3px solid white;
    }
}